@font-face {
  font-family: "GPIcon-Regular";
  src: url("/src/assets/fonts/GPIcon-Regular.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Median";
  src: url("/src/assets/fonts/GPPolySans-Median.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Slim";
  src: url("/src/assets/fonts/GPPolySans-Slim.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Neutral";
  src: url("/src/assets/fonts/GPPolySans-Neutral.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "BasierCircle-Regular";
  src: url("/src/assets/fonts/BasierCircle-Regular.woff") format("woff");
  font-synthesis-weight: auto;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-RegularItalic";
  src: url("/src/assets/fonts/BasierCircle-RegularItalic.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "BasierCircle-Medium";
  src: url("/src/assets/fonts/BasierCircle-Medium.woff") format("woff");
  font-synthesis-weight: auto;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-MediumItalic";
  src: url("/src/assets/fonts/BasierCircle-MediumItalic.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "BasierCircle-SemiBold";
  src: url("/src/assets/fonts/BasierCircle-SemiBold.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-SemiBoldItalic";
  src: url("/src/assets/fonts/BasierCircle-SemiBoldItalic.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "BasierCircle-Bold";
  src: url("/src/assets/fonts/BasierCircle-Bold.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-BoldItalic";
  src: url("/src/assets/fonts/BasierCircle-BoldItalic.otf") format("otf");
  font-synthesis-weight: auto;
  font-weight: 700;
  font-style: italic;
}

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* remove user select from images, etc. */
img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
